<!-- @format -->

<template>
  <mq-layout mq="lg+">
    <header id="header" class="header" :class="[$mq, { scrolled: isScrolled }]">
      <div class="header__content">
        <nav id="navi">
          <BaseNavigation />
        </nav>
      </div>
    </header>
  </mq-layout>
</template>

<script>
/** @format */

export default {
  props: {
    social: {
      type: Boolean,
      default: true,
    },
    kinofinder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDefaultImage: true,
      isScrolled: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(event) {
      if (window.scrollY > 50) {
        this.isScrolled = true
      } else {
        this.isScrolled = false
      }
    },
  },
  computed: {
    companySocial() {
      return () =>
        import(
          `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
        )
    },
  },
}
</script>

<style lang="scss">
/** @format */

.header {
  $self: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: none;
  z-index: 999999;
  overflow: hidden;
  transition: background-color 0.3s ease;
  &.scrolled {
    background-color: #ee6726; /* Background color when scrolled */
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 55px;
    height: $header-height;
    justify-content: center;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 1920px; /* Adjust the width as needed */
      height: 1px;
      background-color: white; /* Set the color of the line */
    }
  }
}
</style>
