<!-- @format -->
<template>
  <mq-layout :mq="['sm', 'md']">
    <div class="mob-index mob-index-top mob-header" id="mob-index mob-header">
      <!--<BaseKinostart :kinostart="kinostart" class="kinostart-mob" />-->

      <img src="@/assets/images/bg_top_mobile.jpg" alt="" />
      <div class="mob-download-co">
        <div class="mob-downloads">
          <div class="left-download" :class="$mq">
            <div class="left-download-bottom" :class="$mq">
              <a
                href="#"
                v-scroll-to="{
                  el: '#mob_dvd',
                  offset: -50,
                }"
                target="_blank"
              >
                <img src="@/assets/images/mobile/new/kinolist-mob.png" alt="" />
              </a>
            </div>
          </div>
          <div class="right-download" :class="$mq">
            <a href="/Divertimento_KC.pdf" target="_blank">
              <img
                src="@/assets/images/mobile/new/schulmaterial-mob.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <BaseKinostart
        :kinostart="kinostart"
        class="kinostart-mob"
        :class="$mq"
      />
    </div>
  </mq-layout>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'

export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    showKinofinder: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.no-scroll {
  overflow: hidden;
  height: 100vh;
  scrollbar-width: 0;
  z-index: 0;
}
.fullScreen {
  height: 100vh;
  z-index: 0;
}
.mob-index-top {
  //margin-top: 50px;
  width: 100%;
  img {
    width: 100%;
  }
}
.mob-index {
  position: relative;
  $self: &;
  img {
    width: 100%;
  }
}

.mob-download-co {
  margin-top: -1px;
  .mob-downloads {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    .left-download {
      width: 53.8%;
    }
    .right-download {
      width: 50%;
    }
  }
}

.kinostart-mob {
  background-color: white;
  color: #ee6726;
  font-family: 'oSWALD';
  width: 100%;
  $min_width: 300px;
  $max_width: 1024px;
  $min_font: 12px;
  $max_font: 55px;
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  padding: 0px 0px;
  z-index: 1;
  font-weight: bold;
  &.sm {
    padding: 0px 0px;
  }
}

.bottom-tr {
  &.sm,
  &.md {
    height: auto;
    left: 0;
    position: relative;
    width: 100%;
    z-index: 10;
  }
}
</style>
