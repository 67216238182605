/** @format */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import VueHead from 'vue-head'
import ScrollReveal from 'vue-scroll-reveal'
import VueTouchRipple from 'vue-touch-ripple'
import 'vue-touch-ripple/dist/vue-touch-ripple.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'vue-material-design-icons/styles.css'
import Config from './config/website.config.json'
import Texte from '@/views/texte/all.json'

// Loads Open Sans
require('typeface-open-sans')

// Loads Oswald
// require('typeface-oswald')

// Lädt alle Vue-Dateien, die mit BASE, Desktop oder Mobile anfangen und registriert diese.
require('./plugins/loadglobals')

// https://alligator.io/vuejs/vue-media-queries/
require('./plugins/breakpoints')

require('./plugins/event-bus')

Vue.config.productionTip = false

// NOTE website.config.js Global in Vue zur Verfügung stellen
Vue.prototype.$config = Config

// NOTE Texte laden
Vue.prototype.$texte = Texte

Vue.use(ScrollReveal)
Vue.use(VueScrollTo)
Vue.use(VueHead)
Vue.use(VueTouchRipple)

new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app')