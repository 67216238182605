<!-- @format -->

<template>
  <div>
    <DesktopHeader />
    <DesktopTop :ratio="ratio" :trailer="trailer" :kinostart="kinostart" />
    <div class="base__clip">
      <div class="base__clip--wrapper">
        <video
          src="@/assets/videos/DIVERTIMENTO_Clip.mp4"
          loop
          muted
          autoplay
        ></video>
      </div>
    </div>
    <!--
    <Crew />
    <Musik />-->
    <Inhalt />
    <div class="teilen-desktop" :class="$mq">
      <div class="teilen-desktop-content" :class="$mq">
        <div class="sharing" :class="$mq">
          <div class="bg-teilen">
            <span>Teilen auf</span>
          </div>
          <div class="icons">
            <a
              target="_blank"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
              class="share__item share__fb"
              ><img src="@/assets/images/top/fb.png" alt="" />
            </a>
            <a
              :href="`https://twitter.com/intent/tweet?text=${$config.twitter.text}`"
              target="_blank"
              class="share__item share__twitter"
            >
              <img src="@/assets/images/top/twitter.png" alt="" />
            </a>
            <a
              :href="$config.instagram.link"
              target="_blank"
              class="share__item share__instagram"
            >
              <img src="@/assets/images/top/ig.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="hintergrund-bg-top" id="hintergrund"></div>
    <Hintergrundinfos />
    <Fotos />
    <div class="bg-fotos-2">
      <div class="bg-fotos-2-content">
        <div class="maedchen_fadein" :class="{ 'fade-in': fadeIn }">
          <img src="@/assets/images/maedchen_unten.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 
    <Fotos />-->
    <div class="maedchen">
      <div class="maedchen-content">
        <div class="maedchen-content-wrapper">
          <div class="maedchen-content-top" :class="$mq">
            <img
              class="biggerimg"
              src="@/assets/images/maedchen_name_small.png"
              alt=""
              :class="$mq"
            />
            <img
              class="smallerimg"
              src="@/assets/images/maedchen_name_small_lg.png"
              alt=""
              :class="$mq"
            />
          </div>
        </div>
      </div>
    </div>
    <Partner />
    <Dvd />
    <BaseFooter :billing="true" :kinostart="kinostart" />
    <BaseYoutube :youtube="$config.youtube" :margin="100" />
  </div>
</template>

<script>
import Inhalt from '@/views/Inhalt.vue'
import Fotos from '@/views/Fotos.vue'
import Hintergrundinfos from '@/views/Hintergrund.vue'
import Partner from '@/views/Partner.vue'
import Dvd from '@/views/Dvd.vue'
/* 
  
  import Regie from '@/views/Regie.vue'
  import Timeline from '@/views/Timeline.vue'
  import Statement from '@/views/Statement.vue'
  import Clip from '@/views/Clip.vue'
   */
import moment from 'moment'

export default {
  data() {
    return {
      fadeIn: false,
    }
  },
  components: {
    Inhalt,
    Fotos,
    Hintergrundinfos,
    Partner,
    Dvd,
    /*  
      Regie, 
      Fotos,
      Timeline,
      Statement,
      Clip,
  */
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  computed: {
    kinostart() {
      return moment() < moment(this.$config.release_line_1.date)
        ? this.$config.release_line_1.line_before
        : this.$config.release_line_1.line_after
    },
    ratio() {
      return (
        this.$store.getters['Window/isSize'].width /
        this.$store.getters['Window/isSize'].height
      )
    },
    trailer() {
      return this.$config.youtube.id !== ''
    },
  },
  methods: {
    handleScroll() {
      const element = document.querySelector('.maedchen_fadein')
      if (element) {
        const rect = element.getBoundingClientRect()
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight
        this.fadeIn = rect.top <= windowHeight
      }
    },
    logout() {
      this.$store.dispatch('Auth/userSignOut')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.base__clip {
  $self: &;
  &--wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.hintergrund-bg-top {
  background: white url($assets + 'images/bg_overhintergrund_02.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 99px;
}
.teilen-desktop {
  width: 100%;
  background: white url($assets + 'images/bg_overhintergrund.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 703px;
  position: relative;
  &.lg {
    background: white url($assets + 'images/bg_overhintergrundlg.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto;
    height: 702px;
  }
  .teilen-desktop-content {
    position: absolute;
    right: 25%;
    top: 60px;
    &.xl {
      right: 20%;
    }
    &.lg {
      right: 10%;
    }
    .sharing {
      display: flex;
      flex-direction: row;
      &.lg {
        span {
          font-size: 15px;
        }
      }
      .bg-teilen {
        background-color: white;
        width: 164px;
        height: 39px;
        border-radius: 30px;
        margin-right: -30px;
        margin-top: 7px;
        span {
          font-size: 23px;
          font-weight: bold;
          font-family: 'Oswald';
          text-align: center;
          text-transform: uppercase;
          line-height: 1.7;
          margin-left: 25px;
        }
      }
      .share__fb,
      .share__twitter,
      .share__instagram {
        margin-right: 5px;
        img {
          width: 28%;
        }
      }
    }
  }
}
.bg-fotos-2 {
  width: 100%;
  background: white url($assets + 'images/gallery/bg_fotos_2.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 592px;
  margin-top: -1px;
  position: relative;
  display: flex;
  .bg-fotos-2-content {
    text-align: center;
    margin-left: 100px;
    .maedchen_fadein {
      position: absolute;
      bottom: -671px;
      width: 90%;
      &.fade-in {
        animation: fade-in 1s forwards;
      }
    }
    @keyframes fade-in {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
.maedchen {
  width: 100%;
  background: white url($assets + 'images/maedchen.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  margin-bottom: 43px;
  .maedchen-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .maedchen-content-wrapper {
      z-index: 9999;
      .maedchen-content-top {
        margin-top: 490px;
        width: 100%;
        img {
          width: 100%;
        }
        &.xl {
          margin-top: 520px;
        }
        &.lg {
          margin-top: 550px;
        }
        .biggerimg {
          &.lg {
            display: none;
          }
        }
        .smallerimg {
          &.xxxxl,
          &.xxxl,
          &.xxl,
          &.xl {
            display: none;
          }
        }
      }
    }
  }
}
</style>
