<template>
  <div class="m-trailer">
    <div class="mob-trailer-content">
      <a
        v-if="$config.youtube.display === true"
        href="#"
        @click.prevent="emitPressClick"
        target="_blank"
        class="m-trailer external"
      >
        <div class="m-trailer__wrapper">
          <video
            src="@/assets/videos/sizzle.mp4"
            loop
            muted
            autoplay
            playsinline
          ></video>
          <div class="m-trailer__text-wrapper">
            <img src="@/assets/images/trailer/play.png" alt="" />
          </div>
        </div>
      </a>
      <YoutubeModal2 />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
import YoutubeModal2 from '../Elements/Modals/YoutubeModal2.vue'
export default {
  components: { YoutubeModal2 },
  props: {
    trailer: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitPressClick() {
      EventBus.$emit('ytmodal')
    },
    ytVideo() {
      EventBus.$emit('open-yt')
    },
  },
}
</script>

<style lang="scss">
.m-trailer {
  position: relative;
  z-index: 9;
  $self: &;
  display: block;
  &__wrapper {
    margin: 0% 4% 0% 4%;
    position: relative;
    cursor: pointer;
    video {
      width: 100%;
    }
    #{$self}__text-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 111;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 20%;
      }
    }
  }
}
</style>
