<template>
  <div class="hintergrund" :class="$mq">
    <div class="slider" id="slider">
      <div
        class="arrow arrow-left"
        :class="[$mq, { active: currentContentType === 0 }]"
        :style="{ opacity: currentContentType === 0 ? 0.5 : 1 }"
      ></div>
      <div
        class="arrow arrow-right"
        :class="[$mq, { active: currentContentType === 2 }]"
        :style="{ opacity: currentContentType === 2 ? 0.5 : 1 }"
      ></div>
      <div class="heading-section">
        <div :class="{ heading: true, active: currentContentType === 0 }">
          <h1>ZAHIA ZIOUANI</h1>
        </div>
        <div :class="{ heading: true, active: currentContentType === 1 }">
          <h1>DAS ORCHESTER</h1>
        </div>
        <div :class="{ heading: true, active: currentContentType === 2 }">
          <h1>DIE GESCHICHTE</h1>
        </div>
      </div>
      <!-- Section 1 -->
      <div class="content-type">
        <div class="full-section" :class="$mq">
          <div class="orange_line1">
            <img src="@/assets/images/hintergrund/orange.jpg" />
          </div>
          <div class="top-section">
            <div class="background-image">
              <img src="@/assets/images/hintergrund/1.jpg" />
            </div>
            <h1>
              WER <br />
              IST ZAHIA<br />
              ZIOUANI?
            </h1>
          </div>
          <div class="orange_line-desktop">
            <img src="@/assets/images/hintergrund/orange.jpg" />
          </div>
          <div class="bottom-section" :class="$mq">
            <p class="text3" :class="$mq">
              Schon als kleines Mädchen begeisterte sich Zahia für klassische
              Musik, die sie im Hause ihrer Eltern hörte. Beethoven, Mozart, die
              großen Opern ... Zahia lernte zunächst Gitarre und dann Bratsche.
            </p>
            <p class="text3" :class="$mq">
              Als Jugendliche entdeckte sie ihr leidenschaftliches Interesse für
              Partituren, mit denen sie die Komplexität symphonischer Musik
              erforschte. Am Konservatorium Pantin in Seine-Saint-Denis
              absolvierte sie eine Ausbildung zur Dirigentin – ihr absoluter
              Traumberuf.
            </p>
            <p class="text3" :class="$mq">
              Im Alter von sechzehn Jahren lernte sie Sergiu Celibidache kennen
              und besuchte ab da seine Masterclass in Paris. Trotz des enormen
              Drucks und der vorherrschenden Einstellung in ihrem Umfeld, der
              Dirigentenberuf sei Männern vorbehalten, gab Zahia nicht auf und
              verfolgte mit Entschlossenheit hartnäckig den Weg, den sie für
              sich selbst sah. Es folgten eineinhalb Jahre Intensivkurse bei
              Celibidache, die sie in ihrer Berufung zur Dirigentin bestätigten.
            </p>
            <p class="text3" :class="$mq">
              Im April 1996 dirigierte Zahia in der Salle Gaveau in Paris das
              Scherzo von Brahms' Symphonie Nr. 3 bei ihrem allerersten
              offiziellen Konzert mit dem Orchester des Lycée Racine (Paris),
              einem Gymnasium, bei dem sie gerade in die letzte Klasse
              eingetreten war. Zwei Jahre später gründete sie gemeinsam mit
              ihrer Schwester Fettouma das Divertimento-Symphonieorchester, das
              sie seither dirigiert und leitet. Fettouma spielt im Orchester als
              Solo-Cellistin, komponiert selbst und engagiert sich in den
              pädagogischen Projekten des Orchesters.
            </p>
            <span class="text3_orange">1998</span>
            <p class="text3_bottom">
              <span>Zahia gründet gemeinsam mit ihrer Schwester Fettouma</span>
            </p>
            <p class="text1_bottom">das Symphonieorchester</p>
          </div>
        </div>
      </div>

      <!-- Section 2 -->
      <div class="content-type">
        <div class="full-section">
          <div class="orange_line1">
            <img src="@/assets/images/hintergrund/orange.jpg" />
          </div>
          <div class="top-section">
            <div class="background-image">
              <img src="@/assets/images/hintergrund/2.jpg" />
            </div>
            <h1>
              DAS<br />
              DIVERTIMENTO-<br />
              ORCHESTER
            </h1>
          </div>
          <div class="orange_line-desktop">
            <img src="@/assets/images/hintergrund/orange.jpg" />
          </div>
          <div class="bottom-section">
            <p class="text3" :class="$mq">
              Dieses Ensemble, das siebzig Laienmusiker aus der ganzen
              Ile-de-France zusammenbringt, zielt insbesondere darauf ab, Werke
              aus dem großen klassischen Repertoire zu entdecken und
              gleichzeitig die jungen Nachwuchsmusiker auf den Beruf des
              Orchesterinstrumentalisten vorzubereiten. Das wichtigste Credo
              ihres Divertimento-Orchesters ist: Kultur und Freude an der Musik
              für alle – ganz egal wie alt du bist und wo du herkommst!
            </p>
            <p class="text3" :class="$mq">
              Mit rund vierzig Konzerten pro Jahr auf den großen nationalen
              Bühnen feiert das in Zahias Heimatgemeinde Seine-Sainte-Denis
              verankerte Divertimento-Orchester zahlreiche Erfolge. Zahia
              Ziouani wird regelmäßig eingeladen, Orchester in Frankreich und im
              Ausland zu dirigieren und mit ihnen zu proben. Ihre Arbeitsweise
              zeichnet eine große Offenheit und umfassendes Engagement für alle
              Musiker aus.
            </p>
            <div class="video-section">
              <div class="video_01" @click="openModal('video_01')">
                <div style="position: relative">
                  <video
                    src="@/assets/videos/01_desktop.mp4"
                    loop
                    muted
                    autoplay
                    playsinline
                  ></video>
                  <div class="clip-misc">
                    <img
                      class="play-btn"
                      :class="$mq"
                      src="@/assets/images/trailer/play.png"
                      alt
                    />
                  </div>
                </div>

                <p class="video_name">Das reale Divertimento-Orchester</p>
              </div>
              <div class="video_02" @click="openModal('video_02')">
                <div style="position: relative">
                  <video
                    src="@/assets/videos/02_desktop.mp4"
                    loop
                    muted
                    autoplay
                    playsinline
                  ></video>
                  <div class="clip-misc">
                    <img
                      class="play-btn"
                      :class="$mq"
                      src="@/assets/images/trailer/play.png"
                      alt
                    />
                  </div>
                </div>

                <p class="video_name">Das Filmorchester</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Section 3 -->
      <div class="content-type">
        <div class="full-section">
          <div class="orange_line1">
            <img src="@/assets/images/hintergrund/orange.jpg" />
          </div>
          <div class="top-section">
            <div class="background-image">
              <img src="@/assets/images/hintergrund/3.jpg" />
            </div>
            <h1>
              VON DER<br />
              GESCHICHTE<br />
              ZUM FILM
            </h1>
          </div>
          <div class="orange_line-desktop">
            <img src="@/assets/images/hintergrund/orange.jpg" />
          </div>
          <div class="bottom-section">
            <p class="text3" :class="$mq">
              Um die Geschichte von Zahia und Fettouma Ziouani auf die Leinwand
              zu bringen, verbrachte die Regisseurin Marie-Castille
              Mention-Schaar zunächst viel Zeit mit den beiden Schwestern und
              ihrer Familie. Durch die Geschichten, die sie so von allen
              Familienmitgliedern erfuhr und durch das ihr entgegengebrachte
              Vertrauen, konnte sie die fiktiven Sequenzen des Films so
              gestalten, dass sie quasi der DNA der realen Figuren entsprachen.
              Zahia und Fettouma coachten die beiden Darstellerinnen Oulaya
              Amamra und Lina El Arabi und übten ausgiebig mit ihnen. Oulaya
              besaß keinerlei musikalische Ausbildung, Lina konnte Geige spielen
              und somit Noten lesen, jedoch ist es ein weiter Weg von der Geige
              zum Cello.
            </p>
            <p class="text3" :class="$mq">
              Während der Dreharbeiten selbst waren Zahia und Fettouma als
              musikalische Leitung anwesend und betreuten alle Aufnahmen der
              musikalischen Stücke im Film. Die übrigen Mitglieder des
              Orchesters im Film sind hingegen allesamt Musiker, waren bisher
              aber keine Schauspieler. Um mit ihnen zu arbeiten ließ sich
              Marie-Castille Mention-Schaar von den Charakteren inspirieren und
              schrieb die Figuren des Films teilweise nachträglich um, sobald
              die Darsteller ihre Figuren für sich ausgearbeitet hatten, wurde
              viel improvisiert und nur wenig geprobt. Durch die real
              dargebotene Musik und diese Vorgehensweise beim Dreh, besticht der
              Film durch eine große Unmittelbarkeit und Authentizität.
            </p>
            <p class="text3_orange">Ein Film von</p>
            <p class="text3_bottom">
              Marie-Castille <span>Mention-Schaar</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="modal"
        :class="{ active: isModalOpen && currentModal === 'video_01' }"
      >
        <div class="modal-content-area">
          <div class="modal-content">
            <video
              ref="video_01"
              src="@/assets/videos/01_Orchester.mp4"
              loop
              playsinline
              @click.prevent
            ></video>
            <!-- Add other video sources for different modals -->
          </div>
        </div>
        <span class="close" @click="closeModal">&times;</span>
      </div>

      <!-- Modal 2 -->
      <div
        class="modal"
        :class="{ active: isModalOpen && currentModal === 'video_02' }"
      >
        <div class="modal-content-area">
          <div class="modal-content">
            <video
              ref="video_02"
              src="@/assets/videos/02_Filmorchester.mp4"
              loop
              playsinline
              @click.prevent
            ></video>
            <!-- Add other video sources for different modals -->
          </div>
        </div>
        <span class="close" @click="closeModal">&times;</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentContentType: 0,
      isModalOpen: false,
      currentModal: null,
      modalVideo: null,
    }
  },
  mounted() {
    const slider = document.querySelector('.slider')
    const contentTypes = document.querySelectorAll('.content-type')

    contentTypes[this.currentContentType].classList.add('active')

    const changeContentType = (event) => {
      const delta = event.deltaY || event.detail || -event.wheelDelta
      const sliderRect = slider.getBoundingClientRect()
      const contentTypes = document.querySelectorAll('.content-type')

      const isSliderVisible =
        sliderRect.top >= 0 &&
        sliderRect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)

      if (delta > 0 && this.currentContentType < contentTypes.length - 1) {
        contentTypes[this.currentContentType].classList.remove('active')
        this.currentContentType++
        contentTypes[this.currentContentType].classList.add('active')
        event.preventDefault()
      } else if (delta < 0 && this.currentContentType > 0) {
        contentTypes[this.currentContentType].classList.remove('active')
        this.currentContentType--
        contentTypes[this.currentContentType].classList.add('active')
        event.preventDefault()
      }
    }

    slider.addEventListener('wheel', changeContentType, { passive: false })
    slider.addEventListener('mousewheel', changeContentType, { passive: false })
    slider.addEventListener('DOMMouseScroll', changeContentType, {
      passive: false,
    })

    const arrowLeft = document.querySelector('.arrow-left')
    const arrowRight = document.querySelector('.arrow-right')

    arrowLeft.addEventListener('click', this.slideLeft)
    arrowRight.addEventListener('click', this.slideRight)

    window.addEventListener('click', (event) => {
      if (event.target.classList.contains('modal')) {
        this.closeModal()
      }
    })

    window.addEventListener('scroll', (event) => {
      const sliderRect = slider.getBoundingClientRect()
      const isSliderVisible =
        sliderRect.top >= 0 &&
        sliderRect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)

      if (isSliderVisible) {
        event.preventDefault()
      }
    })
  },
  methods: {
    isSliderScrolling() {
      const slider = document.querySelector('.slider')
      const contentTypes = document.querySelectorAll('.content-type')

      const sliderRect = slider.getBoundingClientRect()
      const currentSectionRect =
        contentTypes[this.currentContentType].getBoundingClientRect()

      return (
        currentSectionRect.top < sliderRect.top ||
        currentSectionRect.bottom > sliderRect.bottom
      )
    },
    slideLeft() {
      const contentTypes = document.querySelectorAll('.content-type')

      if (this.currentContentType > 0) {
        contentTypes[this.currentContentType].classList.remove('active')
        this.currentContentType--
        contentTypes[this.currentContentType].classList.add('active')
      }
    },
    slideRight() {
      const contentTypes = document.querySelectorAll('.content-type')

      if (this.currentContentType < contentTypes.length - 1) {
        contentTypes[this.currentContentType].classList.remove('active')
        this.currentContentType++
        contentTypes[this.currentContentType].classList.add('active')
      }
    },
    openModal(video) {
      this.isModalOpen = true
      this.currentModal = video

      // Pause the video when the modal opens
      this.modalVideo = this.$refs[video]
      this.modalVideo.pause()
    },
    closeModal() {
      this.isModalOpen = false
      this.currentModal = null

      // Resume the video when the modal closes
      if (this.modalVideo) {
        this.modalVideo.play()
      }
    },
  },
}
</script>

<style lang="scss">
.hintergrund {
  width: 100%;
  background: black url($assets + 'images/hintergrund/bg.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 860px;
  &.lg {
    height: 940px;
  }
}
.modal {
  display: none;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto;
}
.modal-content-area {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  video {
    width: 80%;
  }
}
.close {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  &:hover {
    color: #ee6726;
  }
}

.modal.active {
  display: block;
}
.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.arrow {
  position: absolute;
  top: 450px;
  cursor: pointer;
  z-index: 1;
}

.arrow-left {
  height: 89px;
  width: 89px;
  left: 50px;
  background-image: url($assets + 'images/fotos/pfeil_links.png'); /* Replace with your left arrow image */
  background-position: center;
  background-repeat: no-repeat;
  &.lg {
    left: 15px;
    height: 69px;
    width: 69px;
    background-image: url($assets + 'images/fotos/pfeil_links_lg.png'); /* Replace with your left arrow image */
  }
  &.xl {
    left: 20px;
  }
  &.xxxxl {
    left: 150px;
  }
}

.arrow-right {
  height: 89px;
  width: 89px;
  right: 50px;
  background-image: url($assets + 'images/fotos/pfeil_rechts.png'); /* Replace with your right arrow image */
  background-position: center;
  background-repeat: no-repeat;
  &.lg {
    right: 15px;
    height: 69px;
    width: 69px;
    background-image: url($assets + 'images/fotos/pfeil_rechts_lg.png'); /* Replace with your left arrow image */
  }
  &.xl {
    right: 20px;
  }
  &.xxxxl {
    right: 150px;
  }
}
.heading {
  color: #9d9d9c;
}

.heading.active {
  color: white;
}
.heading-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  h1 {
    font-size: 20px;
    font-family: 'Raleway';
    font-weight: bold;
    margin-bottom: -8px;
    margin-top: -3px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.content-type {
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 auto;
  .full-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top-section {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      h1 {
        color: #ee6726;
        font-size: 50px;
        font-family: 'Oswald';
        text-align: right;
        font-weight: bold;
        line-height: 1.1;
        margin-bottom: 0;
        margin-top: 76px;
        margin-right: 40px;
      }
    }
    .orange_line-desktop {
      text-align: center;
      width: 100%;
    }
    .orange_line1 {
      text-align: center;
      width: 100%;
      margin-bottom: 22px;
    }
    .bottom-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .text3 {
        width: 1278px;
        font-size: 17px;
        font-family: 'Raleway';
        color: white;
        line-height: 1.4;
        margin-bottom: 0;
        &.xl {
          width: 1090px;
        }
        &.lg {
          width: 800px;
        }
      }
      .text3_orange {
        color: #ee6726;
        font-size: 18px;
        font-family: 'Raleway';
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 70px;
      }
      .text3_bottom {
        font-size: 27px;
        font-family: 'Raleway';
        font-weight: 500;
        color: white;
        margin-top: 0;
        span {
          font-weight: bold;
        }
      }
      .text1_bottom {
        font-size: 27px;
        font-family: 'Raleway';
        font-weight: 500;
        color: white;
        margin-top: -25px;
        span {
          font-weight: bold;
        }
      }
      .video-section {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        .video_01,
        .video_02 {
          cursor: pointer;
        }
        .video_02,
        .video_01 {
          .clip-misc {
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 111;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .play-btn {
              width: 16%;
            }
          }
        }
        video {
          margin-left: 18px;
          margin-right: 18px;
          border: 2px solid #878787;
        }
        .video_name {
          color: white;
          text-align: right;
          margin-right: 20px;
          font-size: 17px;
        }
      }
    }
  }
}

.content-type.active {
  display: block;
}

.content-type p {
  color: black;
}

.content-type span {
  font-weight: bold;
}
</style>
