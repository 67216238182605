<!-- @format -->

<template>
  <flickity ref="flickity" :options="flickityOptions">
    <div
      v-for="cite2 in $config.cites2"
      :key="cite2.text"
      class="carousel-cell"
    >
      <cite class="base-cite2" :class="$mq">
        <p class="text2" :class="$mq" v-text="cite2.text" />
        <p class="author2" :class="$mq" v-text="cite2.author" />
      </cite>
    </div>
  </flickity>
</template>

<script>
import Flickity from 'vue-flickity'
require('flickity-fade')

export default {
  components: {
    Flickity,
  },
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        autoPlay: true,
        fade: true,
        draggable: false,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.carousel-cell {
  width: 660px !important;
}
.base-cite2 {
  $self: &;
  width: 660px !important;
  .text2 {
    font-family: 'Jost';
    font-weight: 500;
    color: black;
    font-size: clamp(1.2rem, 5vw, 2.875rem);
    line-height: 1.3;
    margin: 0 auto;
    margin-bottom: -10px;
    text-align: center;
    text-transform: uppercase;
    width: 65% !important;
    font-style: normal;
    &.sm {
      width: 50% !important;
    }
  }
  .author2 {
    font-family: 'Jost';
    font-style: normal;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    color: #0086cd;
    font-size: clamp(1rem, 3.5vw, 1.75rem);
    letter-spacing: 0.025rem;
    padding-top: 0.3vw;
    margin-top: 9px;
  }
}
</style>
