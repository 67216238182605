<template>
  <mq-layout mq="lg+">
    <div class="index" id="index" :class="$mq">
      <div class="index-container" :class="$mq">
        <div class="top-container">
          <div class="cites" :class="$mq">
            <BaseCitesFade />
          </div>
          <div class="top-container-content">
            <div class="top-tt" :class="$mq">
              <img :src="require(`@/assets/images/` + $config.tt)" alt />
            </div>
            <div class="cast" :class="$mq">
              <img src="@/assets/images/top/cast.png" alt="" />
            </div>
            <div class="top-trailer" :class="$mq">
              <div v-if="$config.youtube.display">
                <a href="#" @click.prevent="emitPressClick">
                  <div style="position: relative">
                    <video
                      src="@/assets/videos/sizzle.mp4"
                      loop
                      muted
                      autoplay
                    ></video>
                    <div class="trailer-misc">
                      <img
                        class="play-btn"
                        :class="$mq"
                        src="@/assets/images/trailer/play.png"
                        alt
                      />
                    </div>
                  </div>
                </a>
              </div>
              <YoutubeModal />
            </div>
          </div>
          <div class="top-buttons downloads" id="downloads">
            <div class="top-buttons-content">
              <div class="top-kinolist" :class="$mq">
                <div
                  v-scroll-to="{
                    el: '#dvd',
                    offset: -44,
                  }"
                >
                  <img src="@/assets/images/top/stoerer.png" alt="" />
                </div>
              </div>
              <div class="top-schulmaterial" :class="$mq">
                <a href="/Divertimento_KC.pdf" download>
                  <img src="@/assets/images/top/schulmaterial.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div
            class="top-char"
            :class="$mq"
            v-scroll-reveal.stop="{
              duration: 2500,
              delay: 240,
              origin: 'right',
              distance: '20px',
            }"
          >
            <img
              src="@/assets/images/top/dirigent.png"
              alt=""
              class="small-top"
              :class="$mq"
            />
          </div>
          <div class="right_corner" :class="$mq">
            <img src="@/assets/images/top/orchester_person.png" alt="" />
          </div>
          <div class="left_corner" :class="$mq">
            <img src="@/assets/images/top/left_corner.png" alt="" />
          </div>
        </div>
      </div>
      <BaseKinostart :kinostart="kinostart" class="desktop-kinostart" />
    </div>
  </mq-layout>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
import PressModal from '@/components/Base/Elements/Modals/YoutubeModal.vue'
import BaseCitesFade from '@/components/Base/Cites/BaseCitesFade.vue'
import YoutubeModal from '../Elements/Modals/YoutubeModal.vue'
export default {
  components: { YoutubeModal },
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    components: {
      PressModal,
      BaseCitesFade,
    },
    ratio: {
      type: Number,
      default: 1.78,
    },
    trailer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFixed() {
      return this.position === 'fixed'
    },
  },
  methods: {
    emitPressClick() {
      EventBus.$emit('ytmodal')
    },
    ytVideo() {
      EventBus.$emit('open-yt')
    },
    kinoliste() {
      EventBus.$emit('open-kinoliste')
    },
  },
}
</script>

<style lang="scss">
/** @format */

.isFixed {
  position: fixed;
}
.isAbsolute {
  position: absolute;
}
.index {
  z-index: 0;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0 !important;
  .desktop-kinostart {
    background-color: white;
    position: absolute;
    bottom: -0px;
    width: 100%;
    margin: 0;
    &.lg {
      font-size: 55px;
    }
    &.xl,
    &.xxl,
    &.xxxl,
    &.xxxxl {
      font-size: 55px;
    }
    &.sm,
    &.md {
      font-weight: 500;
      $min_width: 300px;
      $max_width: 1023px;
      $min_font: 22px;
      $max_font: 55px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
  }
  .index-container {
    height: 100vh; /* Set the height to 100% of the viewport height */
    width: 100%;
    background: #f7f4e9 url($assets + 'images/top/bg.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    .top-container {
      .cites {
        position: absolute;
        top: 175px;
        left: -700px;
        width: 100%;
        &.xxl {
          top: 155px;
          left: -670px;
        }
        &.xl {
          top: 150px;
          left: -520px;
        }
        &.lg {
          top: 145px;
          left: -470px;
        }
      }
      .top-container-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .top-tt {
          z-index: 9999;
          margin: 90px 0 31px 0;
          text-align: center;

          &.lg {
            margin: 80px 0 21px 0;
          }
        }
        .cast {
          z-index: 999;
          text-align: center;
          img {
            width: 100%;
          }
          &.xxl {
            img {
              width: 80%;
            }
          }
          &.xl {
            img {
              width: 75%;
            }
          }
          &.lg {
            margin-top: -10px;
            img {
              width: 65%;
            }
          }
        }
        .top-trailer {
          z-index: 9999;
          pointer-events: auto;
          width: 735px;
          height: 415px;
          cursor: pointer;
          margin-top: 15px;
          text-align: center;
          position: relative;
          video {
            width: 60%;
          }
          &.xxl {
            video {
              width: 70%;
            }
          }
          &.lg {
            video {
              width: 60%;
            }
          }
          &.xxxl {
            video {
              width: 100%;
            }
          }
          &.xxxxl {
            video {
              width: 100%;
            }
          }
          .trailer-misc {
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 111;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .play-btn {
              width: 16%;
            }
          }
        }
      }
      .top-buttons {
        position: absolute;
        bottom: 100px;
        width: 100%;
        z-index: 9998;
        .top-buttons-content {
          width: 100%;
          .top-kinolist {
            position: absolute;
            bottom: 0px;
            left: 30px;
            cursor: pointer;
            img {
              width: 100%;
            }
            &.xl,
            &.lg {
              img {
                width: 90%;
              }
            }
          }
          .top-schulmaterial {
            position: absolute;
            right: 20px;
            bottom: 10px;
            &.xl,
            &.lg {
              img {
                width: 90%;
              }
            }
          }
        }
      }
      .top-char {
        position: absolute;
        right: -20px;
        width: 748px;
        height: auto;
        padding: 20px;
        &.xxxl {
          bottom: 20px;
          right: -60px;
          width: 938px;
        }
        &.xxxxl {
          bottom: 20px;
          right: 20px;
          width: 938px;
        }
        &.xl {
          position: absolute;
          right: -160px;
        }
        position: absolute;
        &.lg {
          right: -150px;
          width: 578px;
        }
      }
      .right_corner {
        position: absolute;
        bottom: 82px;
        right: 9%;
        width: 30%;
        img {
          width: 100%;
        }
        &.xxxxl {
          right: 370px;
        }
        &.lg {
          width: 50%;
          right: -14%;
        }
        &.xl {
          width: 40%;
          right: -5%;
        }
        &.xxl {
          width: 35%;
          right: 3%;
        }
      }
      .left_corner {
        position: absolute;
        bottom: 82px;
        left: 0;
        width: 25%;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 1920px), (max-height: 868px) {
  .top-tt {
    img {
      width: 80%;
    }
  }
  .top-trailer {
    video {
      width: 60%;
    }
  }
  .top-char {
    bottom: 20px;
    right: -20px;
    width: 748px;
  }
}
@media screen and (max-width: 1920px) and (max-height: 870px) {
  .top-tt {
    img {
      width: 60%;
    }
  }
  .top-trailer {
    video {
      width: 50%;
    }
  }
  .top-char {
    position: absolute;
    bottom: -70px;
  }
}

@media screen and (max-width: 1599px) and (max-height: 805px) {
  .top-tt {
    img {
      width: 20%;
    }
  }
  .top-trailer {
    video {
      width: 60%;
    }
  }
  .top-char {
    position: absolute;
    bottom: -70px;
  }
}
@media screen and (max-width: 1599px) and (max-height: 807px) {
  .top-tt {
    img {
      width: 60%;
    }
  }
  .top-trailer {
    video {
      width: 55%;
    }
  }
  .top-char {
    position: absolute;
    bottom: -70px;
  }
}
</style>
