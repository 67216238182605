<template>
  <!-- PRESS-MODAL -->
  <div v-if="ytmodal" class="m-yt" :class="$mq">
    <div class="m-yt__content" :class="$mq">
      <p :class="$mq">
        Bitte klicken Sie hier, um Youtube das Abspielen des Videos zu erlauben.
        Durch die Nutzung erklären Sie sich damit einverstanden, dass Ihre
        IP-Adresse und Geräteinformationen verarbeitet werden, um
        Video-Wiedergaben zu ermöglichen und statistische Daten, wie z.B.
        Abrufzahlen, zu ermitteln.
      </p>
      <div class="m-buttons" :class="$mq">
        <a
          :href="$config.youtube.link"
          target="_blank"
          class="m-accept external"
          :class="$mq"
        >
          EINVERSTANDEN
        </a>
        <button class="m-decline" @click="close2" :class="$mq">
          ABBRECHEN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  data() {
    return {
      ytmodal: false,
    }
  },
  created() {
    /* eslint-disable-next-line no-undef */
    EventBus.$on(
      'ytmodal',
      function () {
        this.ytmodal = true
      }.bind(this)
    )
  },
  methods: {
    ytVideo2() {
      EventBus.$emit('open-yt')
    },
    close2() {
      this.ytmodal = false
    },
  },
}
</script>

<style lang="scss">
/** @format */

.m-yt {
  $self: &;
  position: absolute;
  top: 0;
  left: 0.3px;
  width: 92.2%;
  margin-left: 3.9%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  font-family: 'Urbanist';
  .m-yt__content {
    position: relative;
    text-align: center;
    top: 0;
    &.sm {
      margin-top: 0px;
    }
    p {
      font-weight: 500;
      text-align: center;
      margin: 0;
      color: black;
      font-size: 24px;
      &.sm {
        font-size: 12px;
        width: 300px;
      }
      &.md {
        width: 500px;
      }
    }
    .m-buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: none;
        height: 38px;
        line-height: 38px;
        width: 186px;
        background-color: #e20613;
        color: white;
        font-family: 'Urbanist';
        font-weight: bold;
        font-size: 20px;
        margin-right: 5px;
        &.sm {
          height: 24px;
          line-height: 24px;
          width: 140px;
          font-size: 15px;
        }
      }
      button {
        text-decoration: none;
        height: 38px;
        width: 186px;
        background-color: #e20613;
        color: white;
        font-family: 'Urbanist';
        font-weight: bold;
        font-size: 20px;
        border: none;
        cursor: pointer;
        margin-left: 5px;
        &.sm {
          height: 24px;
          width: 140px;
          font-size: 15px;
        }
        &:hover {
          background-color: #b8030f;
        }
      }
    }
  }
}
</style>
