<template>
  <!-- PRESS-MODAL -->
  <div v-if="ytmodal" class="yt" :class="$mq">
    <div class="yt__content" :class="$mq">
      <p>
        Bitte klicken Sie hier, um Youtube das Abspielen<br />
        des Videos zu erlauben. Durch die Nutzung<br />
        erklären Sie sich damit einverstanden, dass<br />
        Ihre IP-Adresse und Geräteinformationen<br />
        verarbeitet werden, um Video-Wiedergaben zu<br />
        ermöglichen und statistische Daten, wie z.B.<br />
        Abrufzahlen, zu ermitteln.
      </p>
      <div class="buttons" :class="$mq">
        <div
          v-if="$config.youtube.display"
          class="accept external"
          :class="$mq"
        >
          <div
            @click="ytVideo"
            class="open-trailer opening-tag"
            style="position: relative"
          >
            <p>EINVERSTANDEN</p>
          </div>
        </div>
        <button class="decline" @click="close" :class="$mq">ABBRECHEN</button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  data() {
    return {
      ytmodal: false,
    }
  },
  created() {
    /* eslint-disable-next-line no-undef */
    EventBus.$on(
      'ytmodal',
      function () {
        this.ytmodal = true
      }.bind(this)
    )
  },
  methods: {
    ytVideo() {
      EventBus.$emit('open-yt')
    },
    close() {
      this.ytmodal = false
    },
  },
}
</script>

<style lang="scss">
/** @format */

.yt {
  $self: &;
  position: relative;
  top: -289px;
  left: 110.3px;
  width: 515px;
  height: 289px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  font-family: 'Urbanist';
  &.xxxxl {
    top: -413px;
    left: -0.7000000000000028px;
    width: 736px;
    height: 413px;
  }
  &.xxxl {
    top: -413px;
    left: -0.7000000000000028px;
    width: 736px;
    height: 413px;
  }
  &.xxl {
    top: -289px;
    left: 110.3px;
    width: 515px;
    height: 289px;
  }
  &.xl {
    top: -248px;
    left: 147.3px;
    width: 442px;
    height: 249px;
  }
  &.lg {
    top: -248px;
    left: 147.3px;
    width: 442px;
    height: 249px;
  }
  .yt__content {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-45%);
    z-index: 99999999;
    p {
      font-weight: 500;
      text-align: center;
      margin: 0;
      width: 100%;
      margin-bottom: 20px;
      font-size: 18px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .accept {
        .opening-tag {
          p {
            text-decoration: none;
            height: 38px;
            line-height: 38px;
            width: 186px;
            background-color: #e20613;
            color: white;
            font-family: 'Urbanist';
            font-weight: bold;
            font-size: 20px;
            margin-right: 5px;
            &:hover {
              background-color: #b8030f;
            }
          }
        }
      }
      a {
        text-decoration: none;
        height: 38px;
        line-height: 38px;
        width: 186px;
        background-color: #e20613;
        color: white;
        font-family: 'Urbanist';
        font-weight: bold;
        font-size: 20px;
        margin-right: 5px;
      }
      button {
        height: 38px;
        width: 186px;
        background-color: #e20613;
        color: white;
        font-family: 'Urbanist';
        font-weight: bold;
        font-size: 20px;
        border: none;
        cursor: pointer;
        margin-left: 5px;
        &:hover {
          background-color: #b8030f;
        }
      }
    }
  }
}
</style>
