<template>
  <div id="fotos" ref="fotos" class="fotos" :class="$mq">
    <BaseCarousel
      :autoplay="false"
      :preview="true"
      :fade="true"
      :fullscreen="false"
      :dots="false"
    >
      <div class="source">
        <div
          v-for="value in this.$config.gallery.count"
          :key="value"
          class="carousel-cell"
        >
          <div class="fotos-caro" :class="$mq">
            <img
              :src="require(`@/assets/images/gallery/` + value + `.png`)"
              alt
              class="pictures"
              :class="$mq"
            />
          </div>
          <div class="fotos-caro-ipad" :class="$mq">
            <img
              :src="
                require(`@/assets/images/gallery/ipad_pictures/` +
                  value +
                  `.jpg`)
              "
              alt
              class="pictures-ipad"
            />
          </div>
        </div>
      </div>
    </BaseCarousel>
    <div class="haare">
      <img src="@/assets/images/gallery/haare_v2.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  /*    props: {
      gallery: {
        type: Boolean,
        default: false,
      },
    },*/
  data() {
    return {
      index: null,
      foto: null,
      images: [],
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    openGallery(number) {
      this.index = number
    },
  },
  mounted() {
    /* for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      } */
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
}
</script>

<style lang="scss">
.fotos {
  background-color: white;
  z-index: 3;
  position: relative;
  cursor: pointer;
  width: 100%;
  background: white url($assets + 'images/gallery/bg_fotos.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  &.lg {
    padding-top: 40px;
  }
  .haare {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-left: 16px;
  }
  .fotos-caro {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &.lg {
      display: none;
    }
  }
  .fotos-caro-ipad {
    &.xl,
    &.xxl,
    &.xxxl,
    &.xxxxl {
      display: none;
    }
  }

  .flickity-prev-next-button {
    border: 0;
    z-index: 333332;
  }

  .flickity-fullscreen-button {
    display: none;
  }
  .flickity-button {
    display: block;
    background: none;
    &:hover {
      background: none;
    }
  }
  .flickity-button-icon {
    fill: white;
    &:hover {
      fill: white;
    }
  }
  .flickity-prev-next-button.next {
    position: absolute;
    right: 75px;
  }
  .flickity-prev-next-button.previous {
    position: absolute;
    left: 75px;
  }
  .flickity-prev-next-button {
    border: 5px solid white;
    background-color: none;
    width: 89px;
    height: 89px;
    border-radius: 50px;
  }
  @media (max-width: 1278px) {
    .flickity-prev-next-button {
      border: 4px solid white;
      background-color: none;
      width: 59px;
      height: 59px;
      border-radius: 50px;
    }
    .flickity-prev-next-button.next {
      position: absolute;
      right: 25px;
    }
    .flickity-prev-next-button.previous {
      position: absolute;
      left: 25px;
    }
  }
  .column {
    float: left;
    width: 33.2%;
    overflow: hidden;
    img,
    video {
      cursor: pointer;
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: transform 1s ease-out;
      -moz-transition: transform 1s ease-out;
      -ms-transition: transform 1s ease-out;
      transition: transform 1s ease-out;

      &:hover {
        -moz-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -o-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }
}
</style>
