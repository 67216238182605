var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mq-layout',{attrs:{"mq":"lg+"}},[_c('div',{staticClass:"index",class:_vm.$mq,attrs:{"id":"index"}},[_c('div',{staticClass:"index-container",class:_vm.$mq},[_c('div',{staticClass:"top-container"},[_c('div',{staticClass:"cites",class:_vm.$mq},[_c('BaseCitesFade')],1),_c('div',{staticClass:"top-container-content"},[_c('div',{staticClass:"top-tt",class:_vm.$mq},[_c('img',{attrs:{"src":require(`@/assets/images/` + _vm.$config.tt),"alt":""}})]),_c('div',{staticClass:"cast",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/cast.png"),"alt":""}})]),_c('div',{staticClass:"top-trailer",class:_vm.$mq},[(_vm.$config.youtube.display)?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.emitPressClick.apply(null, arguments)}}},[_c('div',{staticStyle:{"position":"relative"}},[_c('video',{attrs:{"src":require("@/assets/videos/sizzle.mp4"),"loop":"","muted":"","autoplay":""},domProps:{"muted":true}}),_c('div',{staticClass:"trailer-misc"},[_c('img',{staticClass:"play-btn",class:_vm.$mq,attrs:{"src":require("@/assets/images/trailer/play.png"),"alt":""}})])])])]):_vm._e(),_c('YoutubeModal')],1)]),_c('div',{staticClass:"top-buttons downloads",attrs:{"id":"downloads"}},[_c('div',{staticClass:"top-buttons-content"},[_c('div',{staticClass:"top-kinolist",class:_vm.$mq},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                  el: '#dvd',
                  offset: -44,
                }),expression:"{\n                  el: '#dvd',\n                  offset: -44,\n                }"}]},[_c('img',{attrs:{"src":require("@/assets/images/top/stoerer.png"),"alt":""}})])]),_c('div',{staticClass:"top-schulmaterial",class:_vm.$mq},[_c('a',{attrs:{"href":"/Divertimento_KC.pdf","download":""}},[_c('img',{attrs:{"src":require("@/assets/images/top/schulmaterial.png"),"alt":""}})])])])]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            duration: 2500,
            delay: 240,
            origin: 'right',
            distance: '20px',
          }),expression:"{\n            duration: 2500,\n            delay: 240,\n            origin: 'right',\n            distance: '20px',\n          }",modifiers:{"stop":true}}],staticClass:"top-char",class:_vm.$mq},[_c('img',{staticClass:"small-top",class:_vm.$mq,attrs:{"src":require("@/assets/images/top/dirigent.png"),"alt":""}})]),_c('div',{staticClass:"right_corner",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/orchester_person.png"),"alt":""}})]),_c('div',{staticClass:"left_corner",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/left_corner.png"),"alt":""}})])])]),_c('BaseKinostart',{staticClass:"desktop-kinostart",attrs:{"kinostart":_vm.kinostart}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }