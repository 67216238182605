<template>
  <div class="inhalt-overview" :class="$mq" id="inhalt">
    <div class="inhalt desktop-inhalt" :class="$mq" id="desktop-inhalt">
      <div class="desktop-inhalt-top" :class="$mq">
        <img
          class="desktop-inhalt-top-bigger"
          src="@/assets/images/inhalt/inhalt.png"
          alt=""
          :class="$mq"
        />
        <img
          class="desktop-inhalt-top-smaller"
          src="@/assets/images/inhalt/inhalt_lg.png"
          alt=""
          :class="$mq"
        />
      </div>
      <div class="desktop-inhalt-bottom" :class="$mq">
        <h1 :class="$mq">
          ”Divertimento - Ein Orchester für alle“ wurde beim 33. Internationalen
          Filmfest Emden-Norderney mit dem Hauptpreis ausgezeichnet und zeigt,
          dass es sich lohnt, entgegen aller äußeren Widerstände, für den
          eigenen Traum zu kämpfen.
        </h1>
        <p :class="$mq">
          Zahia Ziouani ist 17 Jahre alt, voller Träume und ehrgeiziger Ziele.
          Gemeinsam mit ihrer Zwillingsschwester Fettouma besucht sie das
          elitäre Pariser Konservatorium. Seit ihrer Kindheit bestimmt die
          klassische Musik ihr Leben. Doch Zahia möchte nicht nur spielen,
          sondern auch den Ton angeben und Dirigentin werden! Dass eine junge
          Frau ein Sinfonieorchester leiten soll? Noch dazu mit algerischen
          Wurzeln? Undenkbar! Also wie schafft man es diesen ehrgeizigen Plan zu
          verfolgen als junge Frau und ohne das nötige Kleingeld? Mit Mut, Herz,
          Verstand und der außergewöhnlichen Absicht ein eigenes Orchester zu
          gründen: Divertimento.
        </p>
        <p :class="$mq">
          <span
            >In der französische Wohlfühl-Komödie mit Anspruch agieret neben dem
            ausgezeichneten jungen Ensemble Niels Arestrup („Schmetterling und
            Taucherglocke“, „Rückkehr nach Montauk“) in der Rolle des
            Stardirigenten Sergiu Celibidache.</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    ratio: {
      type: Number,
      default: 1.78,
    },
    trailer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFixed() {
      return this.position === 'fixed'
    },
  },
  methods: {
    ytVideo() {
      EventBus.$emit('open-yt')
    },
    kinoliste() {
      EventBus.$emit('open-kinoliste')
    },
  },
}
</script>

<style lang="scss">
.inhalt-overview {
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
  .inhalt {
    z-index: 99;
    $self: &;
    width: 100%;
    font-family: 'Raleway';
    padding: 0;
    background-color: white;
    //height: 756px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .desktop-inhalt-top {
      margin-top: -245px;
      text-align: center;
      &.xl {
        margin-top: -175px;
        img {
          width: 90%;
        }
      }
      &.lg {
        margin-top: -145px;
        img {
          width: 100%;
        }
      }
      .desktop-inhalt-top-bigger {
        &.lg {
          display: none;
        }
      }
      .desktop-inhalt-top-smaller {
        &.xxxxl,
        &.xxxl,
        &.xxl,
        &.xl {
          display: none;
        }
      }
    }
    .desktop-inhalt-bottom {
      width: 1278px;
      h1 {
        font-family: 'Oswald';
        font-weight: bold;
        font-size: 25px;
        width: 100%;
      }
      p {
        font-size: 17px;
        line-height: 1.5;
      }
      span {
        font-weight: bold;
      }

      &.xl {
        width: 1090px;
        h1 {
          width: 85%;
          font-size: 23px;
        }
        p {
          font-size: 15px;
        }
      }
      &.lg {
        width: 900px;
        h1 {
          width: 100%;
          font-size: 23px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
