<!-- @format -->

<template>
  <footer class="footer" :class="$mq">
    <div class="footer__content" :class="$mq">
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <div class="prokino_zeigt" :class="$mq">
        <img src="@/assets/images/prokino_zeigt.png" alt="" />
      </div>
      <div class="footer__tt" :class="$mq">
        <img
          :src="require(`@/assets/images/` + $config.tt_small)"
          alt=""
          :class="$mq"
        />
      </div>
      <div class="footer_kinostart_content" :class="$mq">
        <BaseKinostart :kinostart="kinostart" class="footer__kinostart" />
      </div>
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
      <component class="footer__social" :is="companySocial"></component>
    </div>
    <div class="footer__media" :class="$mq">
      <img src="@/assets/images/footer/media.png" alt="" />
    </div>
    <div class="cb-restore"></div>
  </footer>
</template>

<script>
export default {
  props: {
    billing: {
      type: Boolean,
      default: true,
    },
    youtubenote: {
      type: Boolean,
      default: true,
    },
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    teilnahmebedingung: {
      type: String,
      default: 'leer',
    },
  },
  computed: {
    companyFooter() {
      return () =>
        import(
          `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
        )
    },
    companySocial() {
      return () =>
        import(
          `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
        )
    },
  },
}
</script>

<style lang="scss">
.footer {
  $self: &;
  z-index: 5;
  position: relative;
  text-align: center;
  height: 650px;
  font-size: 0.875rem;
  width: 100%;
  padding-bottom: 5rem;
  background-color: white;
  &.lg {
    height: 700px;
  }
  &.sm,
  &.md {
    padding-bottom: 2rem;
  }
  &__content {
    position: relative;
    margin: 0 auto;
    padding-top: 1.5rem;
    font-family: 'Jost';
    &.sm,
    &.md {
      padding: 0;
      margin-top: -5px;
    }

    .prokino_zeigt {
      &.sm,
      &.md {
        display: none;
      }
    }
    .footer__tt {
      margin: 33px 0 25px 0;

      &.lg {
        img {
          width: 60% !important;
        }
      }
      &.sm {
        img {
          margin-top: 0px;
          width: 80%;
        }
      }
      &.md {
        img {
          width: 80%;
          margin-top: 0px;
        }
      }
    }
    .footer_kinostart_content {
      margin-bottom: 28px;
      display: flex;
      justify-content: center;
      .footer__kinostart {
        background-color: #ee6726;
        width: 678px;
        margin: 0;
        color: white;
        &.lg {
          font-size: 40px;
          width: 378px;
        }
        &.xl,
        &.xxl,
        &.xxxl,
        &.xxxxl {
          font-size: 40px;
        }
      }
      &.md,
      &.sm {
        display: none;
      }
    }
  }
  .footer__media {
    margin-bottom: 25px;
  }
  #{$self}__billing {
    margin-top: 15px;
    &.sm,
    &.md {
      margin-top: 10px;
    }
    &.sm,
    &.md,
    &.lg,
    &.xl {
      padding: 0 5%;
    }
    &.xxl,
    &.xxxl,
    &.xxxxl {
      img {
        max-width: 1000px;
      }
    }
  }
}
</style>
