<!-- @format -->
<template>
  <div class="base-kinostart" :class="$mq">
    <!-- eslint-disable-next-line -->
    <div class="text" :class="$mq" v-html="kinostart"></div>
  </div>
</template>
<script>
export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
  },
}
</script>
<style lang="scss">
.base-kinostart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #ee6726;
  font-weight: bold;
  font-family: 'Oswald';
  margin: 10px 0;
  &.md {
    text-align: center;
    width: 95%;
  }
  &.sm {
    font-size: 30px !important;
    text-align: center;
  }
  /*  &.sm {
    font-size: 36px;
    padding: 10px 0;
    margin: 0 auto;
    background-color: white;
    text-align: center;
  }
  &.md {
    font-size: 55px;
    padding: 10px 0;
    margin: 0;
    background-color: white;
    text-align: center;
  } */
}
</style>
