<template>
  <BaseScrollActive class="base-navigation">
    <template v-for="(value, key) in this.$config.navigation">
      <!-- eslint-disable-next-line -->
      <a
        :href="'#' + value.sitename"
        v-if="key !== 'Trailer'"
        :class="[
          'nav-item',
          'scrollactive-item',
          { 'is-active': key === activeItem },
        ]"
        v-scroll-to="{
          el: '#' + value.sitename,
          offset: -40,
        }"
      >
        {{ key }}
      </a>
      <!-- eslint-disable-next-line -->
      <div
        v-if="key === 'Trailer'"
        @click="yt_video"
        class="nav-item open-trailer"
        :class="{ 'is-active': key === activeItem }"
      >
        Trailer
      </div>
    </template>
  </BaseScrollActive>
</template>


<script>
import { EventBus } from '@/plugins/event-bus.js'

export default {
  data() {
    return {
      headerHeight: this.$headerheight,
      activeItem: Object.keys(this.$config.navigation)[0], // Set the first item as active
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.headerHeight = this.$headerheight
      this.activeItem = Object.keys(this.$config.navigation)[0]
    })
  },

  methods: {
    yt_video() {
      EventBus.$emit('open-yt')
    },
  },
}
</script>

<style lang="scss" scoped>
.base-navigation {
  display: flex;
  flex-direction: row;
  .nav-item {
    font-family: 'Oswald';
    position: relative;
    text-transform: uppercase;
    height: $header-height;
    line-height: $header-height;
    padding-right: $header-link-padding;
    color: white;
    font-weight: bold;
    cursor: pointer;
    $min_width: 1024px;
    $max_width: 2560px;
    $min_font: 16px;
    $max_font: 20px;
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    &:hover {
      color: black;
    }
  }
}
.is-active {
  color: black !important;
}
</style>
