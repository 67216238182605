<!-- @format -->

<template>
  <flickity ref="flickity" :options="flickityOptions">
    <div v-for="cite in $config.cites" :key="cite.text" class="carousel-cell">
      <cite class="base-cite">
        <p class="base-cite__text" v-text="cite.text" :class="$mq" />
        <p class="base-cite__author" v-text="cite.author" :class="$mq" />
      </cite>
    </div>
  </flickity>
</template>

<script>
import Flickity from 'vue-flickity'
require('flickity-fade')

export default {
  components: {
    Flickity,
  },
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        autoPlay: true,
        fade: true,
        draggable: false,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.flickity-enabled,
.flickity-viewport {
  height: 160.375px !important;
}
.carousel-cell {
  width: 660px !important;
}
.base-cite {
  $self: &;
  width: 600px !important;
  &__text {
    font-family: 'Oswald';
    font-style: normal;
    color: black;
    font-size: 30px;
    margin: 0 auto;
    margin-bottom: 6px;
    text-align: center;
    width: 25% !important;
    font-weight: bold;
    line-height: 1.1;
    &.lg {
      width: 20% !important;
      font-size: 20px;
    }
    &.xl {
      font-size: 26px;
    }
    &.xxxxl,
    &.xxxl {
      width: 30% !important;
    }
    &.xxl {
      font-size: 26px;
      width: 25% !important;
    }
  }
  &__author {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 17px;
    color: white;
    font-family: 'Raleway';
    margin: 0 auto;
    width: 20%;
    line-height: 1.1;
    &.lg {
      font-size: 12px;
    }
    &.xl {
      font-size: 14px;
    }
    &.xxl {
      font-size: 14px;
    }
  }
}
</style>
