<template>
  <div class="dvd" id="dvd">
    <div class="wrapper container">
      <div class="left_side">
        <img class="packshot" src="@/assets/images/dvd/packshot.png" alt="" />
      </div>
      <div class="right_side">
        <div class="bonusmaterial">
          <h2 class="bonusheader">Bonusmaterial</h2>
          <p class="bonustext">
            Interview mit der Dirigentin Zahia Ziouani und César-Gewinnerin
            Oulaya<br />
            Amamra | Deutscher Kinotrailer | Original Kinotrailer | Trailershow
          </p>
        </div>
        <div class="txt">
          <p>
            <span class="extra">DVD</span>
          </p>
          <div class="amz-btn">
            <a href="https://amzn.to/488esxl" class="vod_btn" target="_blank">
              <img src="@/assets/images/dvd/amazon.jpg" alt="" />
            </a>
            <!--<span>*</span>-->
          </div>
        </div>
        <div class="video">
          <h2>Video on Demand</h2>
          <ul class="vod_btns">
            <li>
              <a
                href="https://tv.apple.com/de/movie/divertimento---ein-orchester-fur-alle/umc.cmc.1ky4041gdvgum8ax3simkfhop"
                target="_blank"
                ><img src="@/assets/images/dvd/itunes.jpg"
              /></a>
            </li>
            <li>
              <a href="https://amzn.to/3sH1h6i" target="_blank"
                ><img src="@/assets/images/dvd/prime.jpg"
              /></a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/movies/details/Divertimento_Ein_Orchester_f%C3%BCr_alle?id=LlXHNf5eKgQ.P"
                target="_blank"
                ><img src="@/assets/images/dvd/google.jpg"
              /></a>
            </li>
            <li>
              <a
                href="https://www.videoload.de/film/divertimento-ein-orchester-fuer-alle/GN_MV020376420000"
                target="_blank"
                ><img src="@/assets/images/dvd/videoload.jpg"
              /></a>
            </li>
            <li>
              <a
                href="https://store.sky.de/product/divertimento-ein-orchester-fur-alle/219adf7e-6f23-4314-94b4-49855f2cbe4b"
                target="_blank"
                ><img src="@/assets/images/dvd/sky.jpg"
              /></a>
            </li>
            <li>
              <a
                href="https://www.kino-on-demand.com/movies/divertimento-ein-orchester-fur-alle"
                target="_blank"
                ><img src="@/assets/images/dvd/ondemand.png"
              /></a>
            </li>
          </ul>
          <p class="note">
            * Die mit Sternchen (**) gekennzeichneten Links sind sogenannte
            Affiliate-Links. Wenn Sie auf einen Affiliate-Link klicken und
            über<br />
            diesen Link einkaufen, erhält Prokino vom betreffenden Online-Shop
            oder Anbieter eine Provision. Für Sie verändert sich der Preis
            nicht.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
export default {
  props: {
    kinostart: {
      type: String,
      default: 'Releaseline',
    },
    ratio: {
      type: Number,
      default: 1.78,
    },
    trailer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFixed() {
      return this.position === 'fixed'
    },
  },
  methods: {
    ytVideo() {
      EventBus.$emit('open-yt')
    },
    kinoliste() {
      EventBus.$emit('open-kinoliste')
    },
  },
}
</script>

<style lang="scss">
.dvd {
  z-index: 99;
  $self: &;
  width: 1024px;
  height: 388px;
  font-family: 'Urbanist';
  padding: 0;
  padding-bottom: 20px;
  margin: 0 auto 20px auto;
  border-bottom: 1px solid #ee6726;
  .container {
    padding: 0;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    .left_side {
      margin-top: 25px;
      margin-right: -30px;
    }
    .bonusmaterial {
      .bonusheader {
        font-weight: 700;
        font-size: 30px;
        text-transform: none !important;
        color: black;
        margin-left: 66px;
        margin-bottom: 0 !important;
        margin-top: 10px !important;
        font-family: 'Oswald';
      }
      .bonustext {
        margin-left: 66px;
        font-family: 'Raleway';
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
    .txt {
      margin-top: 10px;
      flex: 1;
      margin-left: 66px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .extra {
        font-weight: 700;
        font-size: 30px;
        color: #ee6726;
        font-family: 'Oswald';
      }
      p {
        margin-bottom: 0;
      }
      ul {
        margin-top: 10px;
        padding-left: 0 !important;
      }

      ul,
      li {
        //font-size: 6px;
        margin-left: 10px;
        margin-bottom: 0;
      }
      li span {
        font-size: 18px;
      }
      .amz-btn {
        display: flex;
        height: 46px;
        margin-left: 20px;
        margin-top: 10px;
        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        span {
          display: block;
          margin-left: 3px;
          font-weight: 700;
          font-family: 'Oswald';
        }
      }

      .vod_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        margin-top: 5px;
        margin-bottom: 77px;
        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        img {
          vertical-align: middle;
          max-height: 100%;
        }
      }
    }

    h2 {
      font-weight: 700;
      font-size: 30px;
      text-transform: uppercase;
      color: #ee6726;
      margin-left: 66px;
      margin-bottom: 0 !important;
      margin-top: 30px !important;
      font-family: 'Oswald';
    }

    .vod_btns {
      margin-left: 60px;
      position: relative;
      width: 526px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      /* vertical */
      justify-content: flex-start;
      /* horizontal */
      align-items: stretch;
      padding: 0 !important;
      margin-top: 0 !important;
      /* same height */
      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      li {
        list-style: none;
        margin: 7px;
      }

      .vod_btn {
        flex: 0 1 50%;
        margin: 0 20px 14px 0;
        padding: 0 !important;
        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
    .note {
      font-size: 10px;
      line-height: 0.8rem;
      font-weight: 500;
      text-align: left;
      font-family: 'Roboto';
      margin-top: -10px;
      margin-left: 65px;
    }
  }
}
</style>
